import React from "react";
import _ from "lodash";
import Typist from "react-typist";
import TypistLoop from "react-typist-loop";
import Loadable from "react-loadable";
import "animate.css/animate.min.css";
import ReactMarkdown from "react-markdown";
import ScrollAnimation from "react-animate-on-scroll";

import { Layout } from "../components/index";
import Loading from "../components/Loading";
const LoadableComponent = Loadable({
  loader: () => import("../components/Testimonials"),
  loading: Loading
});

export default class Home extends React.Component {
  render() {
    const symptoms = [
      "back tension.",
      "tension headaches.",
      "muscle fatigue.",
      "stress."
    ];
    return (
      <Layout {...this.props}>
        <div className="home-strip hero-strip">
          {this.props.pageContext.site.data.notice.showNotice && (
            <div className="notice-strip">
              <div className="notice-strip__inner">
                <div className="notice">
                  <div className="notice__icon">
                    <img src="/images/announce.svg" width="50" alt="" />
                  </div>
                  <div className="notice__text">
                    <ReactMarkdown
                      source={_.get(
                        this.props,
                        "pageContext.site.data.notice.notice"
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="home-strip__inner hero-strip__inner">
            <div className="hero-strip__col">
              <h1>
                Remedial relief from
                <span className="symptom">
                  <TypistLoop interval={0}>
                    {symptoms.map(symptom => (
                      <Typist
                        key={symptom}
                        cursor={{ show: false }}
                        avgTypingDelay={45}
                      >
                        {symptom}
                        <Typist.Backspace count={symptom.length} delay={3000} />
                      </Typist>
                    ))}
                  </TypistLoop>
                </span>
              </h1>
            </div>
            <div className="hero-strip__col">
              {/* eslint-disable */}
              <video controls poster="/images/poster1.jpg">
                <source
                  src="https://bmg1.s3.eu-west-1.amazonaws.com/homepage.mp4"
                  type="video/mp4"
                  preload="metadata"
                />
                Sorry, your browser doesn't support embedded videos.
              </video>
              {/* eslint-enable */}
            </div>
          </div>
        </div>

        {_.map(
          _.get(this.props, "pageContext.site.data.home.sections"),
          (section, key) => (
            <div className="home-strip" key={key}>
              <div className="home-strip__inner">
                <div className="home-strip__image">
                  <img
                    loading={key !== 0 ? "lazy" : "auto"}
                    src={
                      "https://ik.imagekit.io/oi85dhs4k/tr:w-515" +
                      section.image
                    }
                    alt=""
                  />
                </div>
                <ScrollAnimation animateIn="fadeIn">
                  <div className="home-strip__text">
                    <div className="home-strip__text-card">
                      <h2>{section.title}</h2>
                      {section.content}
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          )
        )}

        <div className="home-strip home-strip-book">
          <a href="/book">Book now</a>
        </div>
        <LoadableComponent {...this.props} />
      </Layout>
    );
  }
}
